'use client'
import {
  RaButton,
  RaContainer,
  RaHeading,
  RaLink,
} from '@components/core/index.core'
import { Loop } from '@utilities/helpers/core.helpers'
import { RaAccordion } from '@components/features/RaAccordion/RaAccordion'
import Link from 'next/link'

import type { ThemeMenu } from '@typings'

import styles from './ra-mobile-menu.module.scss'
import { ICONS } from '@constants/icons.constants'
import { useUi } from '@contexts/ui.context'

export const RaMobileMenu = ({ menu }) => {
  const { closeModal } = useUi()
  return (
    <div className={styles['ra-mobile-menu']}>
      <RaContainer>
        <div className={styles['ra-mobile-menu__close']}>
          <RaButton
            variant="icon"
            icon={ICONS.CLOSE_ICON}
            textColor="light"
            onClick={closeModal}
          />
        </div>
        {Loop(menu, (section: ThemeMenu, i: number) => {
          const content = (
            <div
              key={i}
              className={styles['ra-mobile-menu__accordion-content']}>
              {Loop(section.subitems, (subItem, n: number) => {
                if (subItem?.subitems?.length) {
                  const subItemContent = (
                    <div
                      className={styles['ra-mobile-menu__accordion-subitem']}>
                      {Loop(subItem.subitems, (sub_subItem, i) => (
                        <RaLink
                          key={i}
                          url={sub_subItem.link?.url}
                          title={sub_subItem.link?.title}
                          textColor="light"
                        />
                      ))}
                    </div>
                  )
                  return (
                    <RaAccordion
                      variant="mobile-nav-subitem"
                      title={subItem.link.title}
                      titleLink={subItem.link.url}
                      content={subItemContent}
                    />
                  )
                } else {
                  return (
                    <RaLink
                      key={n}
                      url={subItem.link?.url}
                      title={subItem.link?.title}
                      textColor="light"
                    />
                  )
                }
              })}
            </div>
          )

          return (
            <div
              key={i}
              className={styles['ra-mobile-menu__accordion']}>
              {section.subitems?.length ? (
                <RaAccordion
                  variant="mobile-nav"
                  title={section.link.title}
                  content={content}
                />
              ) : (
                <Link
                  prefetch={false}
                  href={section.link.url}
                  title={section.link.title}
                  target={section.link.target}>
                  <RaHeading
                    component="h3"
                    color="light"
                    noPadding>
                    {section.link.title}
                  </RaHeading>
                </Link>
              )}
            </div>
          )
        })}
      </RaContainer>
    </div>
  )
}
