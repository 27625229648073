'use client'
import { ForwardedRef,forwardRef, useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'

import styles from './ra-portal.module.scss'

interface IRaPortal {
  children: React.ReactNode
  color?: 'light' | 'dark'
  size?: number
}

const MAX_SIZE = 416

export const RaPortal = forwardRef(
  (
    { children, color = 'light', size = 248 }: IRaPortal,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [active, setActive] = useState(false)
    if (size > MAX_SIZE) size = MAX_SIZE

    const [currentSize, setCurrentSize] = useState(size)

    const variant =
      color === 'light' ? 'var(--color-neutral-1)' : 'var(--color-neutral-2)'

    const handleResize = () => {
      const trigger = (ref as any).current
      if (trigger.offsetWidth < size) {
        setCurrentSize(trigger.offsetWidth - 24)
      }
      if (trigger.offsetWidth > size && currentSize < size) {
        setCurrentSize(size)
      }
    }

    useEffect(() => {
      const trigger = (ref as any).current
      trigger?.addEventListener('mouseenter', () => setActive(true))
      trigger?.addEventListener('mouseleave', () => setActive(false))
      window.addEventListener('resize', handleResize)
      return () => {
        trigger?.removeEventListener('mouseenter', () => {})
        trigger?.removeEventListener('mouseleave', () => {})
        window.removeEventListener('resize', handleResize)
      }
    }, [ref])

    const classes = useMemo(() => {
      return clsx(styles['ra-portal'], active && styles['active'])
    }, [active])
    return (
      <div
        className={classes}
        style={{
          maxWidth: currentSize,
          maxHeight: currentSize,
        }}>
        <svg
          width={currentSize}
          height={currentSize}
          viewBox={`0 0 ${currentSize} ${currentSize}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle
            cx={currentSize / 2}
            cy={currentSize / 2}
            r={currentSize / 2}
            stroke={variant}
            strokeDasharray="6 6"
          />
        </svg>
        <div
          className={styles['ra-portal__content']}
          style={{
            maxWidth: currentSize,
            maxHeight: currentSize,
          }}>
          {children}
        </div>
      </div>
    )
  }
)
