import { memo, useMemo } from 'react'
import clsx from 'clsx'

import './ra-blog-image-alignment.style.scss'
import styles from './ra-rich-text.module.scss'

import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'
import { stripUnsafeHtml } from '@utilities/helpers/strip-unsafe-html'

interface RaRichTextProps {
  content?: string
  decorateListsWithCheckmarks?: boolean
  decorateListsWithBullets?: boolean
  decorateTables?: boolean
  headingColor?: 'primary' | 'light' | 'dark'
  small?: boolean
}

export const RaRichText = memo(
  ({
    content,
    decorateListsWithCheckmarks,
    decorateListsWithBullets,
    decorateTables,
    headingColor = 'primary',
    small = false,
  }: RaRichTextProps) => {
    const getSafeHTML = useMemo(() => {
      if (!content) return null
      const sanitized = stripUnsafeHtml(content)
      return stripFrontendUrl(sanitized)
    }, [content])

    return (
      <div
        className={clsx(
          styles['ra-rich-text'],
          decorateListsWithCheckmarks &&
            styles['ra-rich-text--decorate-lists--checkmarks'],
          decorateListsWithBullets &&
            styles['ra-rich-text--decorate-lists--bullets'],
          decorateTables && styles['ra-rich-text--decorate-tables'],
          small && styles['ra-rich-text--small'],
          headingColor && styles[`ra-rich-text--heading-${headingColor}`]
        )}
        dangerouslySetInnerHTML={{ __html: getSafeHTML }}></div>
    )
  }
)

RaRichText.displayName = 'RaRichText'
