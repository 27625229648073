import { RaIcon, RaTypography } from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import styles from './ra-navbar-logo.module.scss'

export const ErlebeLogo = ({ subtitle, isOpen, isFamilySite = false }) => {
  let logoContent = null
  if (isFamilySite && subtitle) {
    const [split1, split2] = subtitle.split('{{ea-herz}}')
    logoContent = (
      <div className={styles['erlebe-family']}>
        <RaTypography
          component="span"
          fontFamily="heading"
          size="s"
          color={isOpen ? 'dark' : 'light'}>
          {split1}{' '}
        </RaTypography>
        {subtitle.includes('{{ea-herz}}') && (
          <RaIcon
            icon={ICONS.ERLEBE_HEART}
            color={isOpen ? 'dark' : 'light'}
          />
        )}
        {split2 && (
          <RaTypography
            component="span"
            fontFamily="heading"
            size="s"
            color={isOpen ? 'dark' : 'light'}>
            {split2}
          </RaTypography>
        )}
      </div>
    )
  }

  return (
    <div className={styles['logo__titles']}>
      <RaIcon
        icon={ICONS.LOGO_ERLEBE}
        color={isOpen ? 'erlebe' : 'light'}
      />
      {subtitle && !isFamilySite && (
        <RaTypography
          component="span"
          fontFamily="heading"
          size="s"
          color={isOpen ? 'dark' : 'light'}>
          {subtitle}
        </RaTypography>
      )}
      {logoContent}
    </div>
  )
}
