'use client'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import {
  RaHeading,
  RaIcon,
  RaLink,
  RaRichText,
  RaTypography,
} from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import clsx from 'clsx'

import styles from './ra-accordion.module.scss'

interface RaAccordionProps {
  title: string
  titleLink?: string
  content: string | React.ReactNode
  variant?: 'rich-content' | 'read-more' | 'mobile-nav' | 'mobile-nav-subitem'
}

export const RaAccordion = memo(
  ({
    title,
    titleLink,
    content,
    variant = 'rich-content',
  }: RaAccordionProps) => {
    const bodyRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [currentHeight, setCurrentHeight] = useState(0)

    const toggleOpen = () => setOpen(!open)

    useEffect(() => {
      if (!bodyRef?.current) return null

      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          const cr = entry.contentRect
          if (cr.height && cr.height !== currentHeight)
            setCurrentHeight(cr.height)
        }
      })

      for (const child of bodyRef.current.children) {
        observer.observe(child)
      }

      return () => {
        observer.disconnect()
      }
    }, [])

    const accordionStyles = useMemo(() => {
      return clsx(
        styles['ra-accordion'],
        styles[`ra-accordion--${variant}`],
        open && styles['ra-accordion--open']
      )
    }, [open, variant])

    const isMenuVariant =
      variant === 'mobile-nav' || variant === 'mobile-nav-subitem'

    return (
      <div className={accordionStyles}>
        <button
          type="button"
          className={styles['ra-accordion__header']}
          onClick={toggleOpen}
          aria-label={title}>
          {variant === 'read-more' || variant === 'mobile-nav-subitem' ? (
            <>
              {titleLink ? (
                <RaLink
                  title={title}
                  url={titleLink}
                />
              ) : (
                <RaTypography
                  component="span"
                  weight={variant === 'read-more' ? 'semibold' : 'regular'}
                  color={variant === 'read-more' ? 'dark' : 'light'}>
                  {title}
                </RaTypography>
              )}
            </>
          ) : (
            <RaHeading
              component={variant === 'mobile-nav' ? 'h3' : 'h5'}
              color={isMenuVariant ? 'light' : 'primary'}
              noPadding>
              {title}
            </RaHeading>
          )}
          <RaIcon
            icon={variant === 'read-more' ? ICONS.CHEVRON_DOWN : ICONS.PLUS}
            color={isMenuVariant ? 'light' : 'dark'}
          />
        </button>
        <div
          ref={bodyRef}
          className={styles['ra-accordion__body']}
          style={{
            height: open ? currentHeight + 12 : 0,
          }}>
          {typeof content === 'string' && <RaRichText content={content} />}
          {typeof content !== 'string' && content}
        </div>
      </div>
    )
  }
)

RaAccordion.displayName = 'RaAccordion'
