'use client'
import { GA4_EVENTS } from '@constants/ga4-events.constants'
import { capitalize } from '@utilities/helpers/capitalize.helpers'
import { getAnalyticsConsent } from '@utilities/helpers/get-analytics-consent.helpers'
import { getCurrency } from '@utilities/helpers/get-currency.helpers'
import { getCurrentEnv } from '@utilities/helpers/get-current-env.helpers'
import { getUserId } from '@utilities/helpers/get-user-id.helpers'
import { getUserIp } from '@utilities/helpers/get-user-ip.helpers'
import { isServer } from '@utilities/helpers/is-server'
import { makeCartItem } from '@utilities/helpers/make-cart-item.helpers'
import { transformProductType } from '@utilities/helpers/transform-product-type.helpers'
import {
  BuildingBlockPostType,
  CartItemProps,
  GA4CartItemProps,
  Ga4EcommerceItemProps,
  RoundtripPostType,
  WordpressPage,
  WordpressPost,
} from '@typings'

import { ga4GetContentGroup } from './ga4-get-content-group.helpers'
import { ga4GetTotalPrice } from './ga4-get-total-price.helpers'
import { ga4MakeBookingFormDatalayer } from './ga4-make-bookingform-datalayer.helpers'
import { ga4MakeCartEventsPayload } from './ga4-make-cart-events-payload.helperts'
import { ga4MakeEcommerceItem } from './ga4-make-ecommerce-item.helpers'
import { ga4MakeGA4Item } from './ga4-make-ga4-item.helpers'
import { ga4MakeRequestFormDataLayer } from './ga4-make-request-form-datalayer.helpers'

interface GA4PaginationPage {
  number: number | string
  title: string
}

export class GA4Events {
  mode: string
  dataLayer: any
  gtmId: string
  clientId: string
  clientIp: string
  env: string
  dispatchEvent: (payload: any, skipConsent?: boolean) => void
  resetDataLayer: () => void

  constructor({ mode = 'client', gtmId }) {
    this.mode = mode
    this.gtmId = gtmId
    this.dataLayer = !isServer && (window as any).dataLayer
    this.clientId = getUserId()
    this.clientIp = getUserIp()
    this.env = getCurrentEnv()
    ;(this.dispatchEvent = (payload, skipConsent = false) => {
      // const consent = getAnalyticsConsent()
      // if (!consent && !skipConsent) return null
      payload = {
        ...payload,
        clientId: this.clientId,
        clientIp: this.clientIp,
      }
      if (this.mode === 'client' && !isServer) {
        if (!this.dataLayer) return null
        this.resetDataLayer()
        this.dataLayer.push(payload)
      }
    }),
      (this.resetDataLayer = () => {
        if (this.mode === 'client' && !isServer) {
          if ((window as any).google_tag_manager) {
            const gtm = (window as any).google_tag_manager[this.gtmId]
            if (gtm) {
              gtm.dataLayer?.reset()
            }
          }
        }
      })
  }

  setCookieConsent = (cookieId: string, value: string) => {
    const event = {
      event: `${cookieId} ${value}`,
    }

    // Skip consent check, as we always need to be able to tell GTM what the updated consent status is
    this.dispatchEvent(event, true)
  }

  viewPage(destination: string, page: WordpressPage) {
    const contentGroup = ga4GetContentGroup()

    const event = {
      event: GA4_EVENTS.PAGEVIEW,
      virtualTitle: page.yoast_title,
      virtualUrl: page.link,
      destination: capitalize(destination),
      contentGroup: contentGroup,
    }
    this.dispatchEvent(event)
  }

  viewBuildingBlock(destination: string, page: BuildingBlockPostType) {
    const extractCartData = makeCartItem(destination, page)
    const { ecommerce, rest } = ga4MakeCartEventsPayload([extractCartData])
    const event = {
      event: GA4_EVENTS.PAGEVIEW_BUILDINGBLOCK,
      virtualTitle: page.yoast_title,
      virtualUrl: page.link,
      destination: capitalize(destination),
      contentGroup: ga4GetContentGroup(),
      ecommerce,
      ...rest,
    }
    this.dispatchEvent(event)
  }

  viewRoundtrip(destination: string, page: RoundtripPostType) {
    const extractCartData = makeCartItem(destination, page)
    const { ecommerce, rest } = ga4MakeCartEventsPayload([extractCartData])
    const event = {
      event: GA4_EVENTS.PAGEVIEW_ROUNDTRIP,
      virtualTitle: page.yoast_title,
      virtualUrl: page.link,
      destination: capitalize(destination),
      contentGroup: ga4GetContentGroup(),
      ecommerce,
      ...rest,
    }
    this.dispatchEvent(event)
  }

  viewBlog(destination: string, page: WordpressPost) {
    const event = {
      event: GA4_EVENTS.PAGEVIEW,
      virtualTitle: page.yoast_title,
      virtualUrl: page.link,
      destination: capitalize(destination),
      contentGroup: ga4GetContentGroup(),
    }
    this.dispatchEvent(event)
  }

  viewTravelplanPage() {
    // Todo - Riksja will measure this separately in the future, leave it here for now
    // const event = {
    //   event: GA4_EVENTS.PAGEVIEW,
    //   virtualTitle: page.yoast_title,
    //   virtualUrl: page.link,
    //   destination: capitalize(destination),
    //   contentGroup: ga4GetContentGroup(),
    // }
    // this.dispatchEvent(event)
  }

  viewCart(cartItems) {
    if (!cartItems?.length) return null
    const { ecommerce, rest } = ga4MakeCartEventsPayload(cartItems)
    const event = {
      event: GA4_EVENTS.VIEW_CART,
      ecommerce,
      ...rest,
    }

    this.dispatchEvent(event)
  }

  addToCart(items: CartItemProps[]) {
    const { ecommerce, rest } = ga4MakeCartEventsPayload(items)
    const event = {
      event: GA4_EVENTS.ADD_TO_CART,
      ecommerce,
      ...rest,
    }

    this.dispatchEvent(event)
  }

  removeFromCart(removedItems: CartItemProps[]) {
    if (!removedItems.length) {
      const event = {
        event: GA4_EVENTS.REMOVE_FROM_CART,
        ecommerce: {
          value: 0,
          currency: getCurrency(),
          items: null,
        },
        rest: {
          tripType: null,
          country: null,
          region: null,
          tripName: null,
          tripPrice: 0,
          tripScheme: null,
          tripDuration: null,
        },
      }
      this.dispatchEvent(event)
    } else {
      const { ecommerce, rest } = ga4MakeCartEventsPayload(removedItems)
      const event = {
        event: GA4_EVENTS.REMOVE_FROM_CART,
        ecommerce,
        ...rest,
      }

      this.dispatchEvent(event)
    }
  }

  requestFormStart(
    cartItems: CartItemProps[],
    fieldValues: object,
    page: GA4PaginationPage
  ) {
    const products = cartItems?.map(p => ga4MakeGA4Item(p) || null)
    const payload = ga4MakeRequestFormDataLayer(products, fieldValues, page)
    const event = {
      event: GA4_EVENTS.REQUEST_FORM_OPEN,
      ...payload,
    }
    this.dispatchEvent(event)
  }

  requestFormPage(
    cartItems: CartItemProps[],
    fieldValues: object,
    page: GA4PaginationPage
  ) {
    const products = cartItems?.map(p => ga4MakeGA4Item(p)) || null
    const payload = ga4MakeRequestFormDataLayer(products, fieldValues, page)
    const event = {
      event: GA4_EVENTS.REQUEST_FORM_PAGINATION,
      ...payload,
    }
    this.dispatchEvent(event)
  }

  requestFormSubmit(
    cartItems: CartItemProps[],
    fieldValues: object,
    transactionId: string,
    page: GA4PaginationPage
  ) {
    const products = cartItems?.map(p => ga4MakeGA4Item(p)) || null
    const payload = ga4MakeRequestFormDataLayer(products, fieldValues)
    const event = {
      event: GA4_EVENTS.REQUEST_FORM_SUBMIT,
      ...payload,
      ecommerce: {
        ...payload.ecommerce,
        transaction_id: transactionId,
      },
      stepNumber: page?.number,
      stepName: page?.title,
    }
    this.dispatchEvent(event)
  }

  bookingFormPage(
    page: GA4PaginationPage,
    travelplan?: any, // todo
    travelplanProducts?: CartItemProps[],
    transactionId?: string,
    isLastStep?: boolean
  ) {
    let ecommerceItems: Ga4EcommerceItemProps[] = null
    let ga4CartItems: GA4CartItemProps[] = null
    let mainProduct: GA4CartItemProps = null

    const travelplanDatalayer = ga4MakeBookingFormDatalayer(travelplan)
    if (travelplanProducts) {
      ga4CartItems = travelplanProducts.map(p => ga4MakeGA4Item(p))
      mainProduct = ga4CartItems?.[0]
      ecommerceItems = ga4CartItems.map(p => ga4MakeEcommerceItem(p))
    }

    const pax = travelplanDatalayer.adults || 1
    const eventName = isLastStep
      ? GA4_EVENTS.BOOKING_FORM_SUBMIT
      : GA4_EVENTS.BOOKING_FORM_PAGINATION

    const event = {
      event: eventName,
      ecommerce: {
        value: ga4GetTotalPrice(ga4CartItems, pax) || null,
        currency: getCurrency(),
        transaction_id: transactionId || null,
        items: ecommerceItems,
      },
      tripType: transformProductType(mainProduct?.type) || null,
      country: mainProduct?.country || null,
      region: mainProduct?.region || null,
      tripName: mainProduct?.title || null,
      tripPrice: mainProduct?.price || null,
      tripScheme: mainProduct?.itinerary || null,
      ...(travelplanDatalayer || null),
      stepNumer: page.number,
      stepName: page.title,
    }

    this.dispatchEvent(event)
  }

  openEnquiryModal() {
    const event = {
      event: GA4_EVENTS.ENQUIRY_FORM_OPEN,
      env: this.env,
      contentGroup: ga4GetContentGroup(),
    }
    this.dispatchEvent(event)
  }
  submit(subject: string) {
    const event = {
      event: GA4_EVENTS.ENQUIRY_FORM_SUBMIT,
      env: this.env,
      contentGroup: ga4GetContentGroup(),
      subject,
    }
    this.dispatchEvent(event)
  }

  errorPage(error) {
    const event = {
      event: isServer ? GA4_EVENTS.SERVER_ERROR : GA4_EVENTS.PAGEVIEW_ERROR,
    }

    this.dispatchEvent(event)
  }
}
