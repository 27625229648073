'use client'
import { memo, useMemo, useState } from 'react'
import {
  RaButtonTravelplan,
  RaContainer,
  RaIcon,
  RaNavbarLogo,
  RaTypography,
  RaNavbarMobile,
  RaNavbarInteraction,
  RaNavbarDropdown,
} from '@components/core/index.core'
import { ICONS } from '@constants/icons.constants'
import Link from 'next/link'
import { SETTINGS } from '@constants/settings.constants'
import type { ThemeSettings } from '@typings'
import { stripFrontendUrl } from '@utilities/helpers/strip-fe-url.helpers'

import styles from './ra-navbar.module.scss'

interface RaNavbarProps {
  themeSettings: ThemeSettings
  destination?: string
  forceNavbar?: boolean
  isFamilySite?: boolean
}

export const RaNavbar = memo(
  ({
    themeSettings,
    destination,
    forceNavbar,
    isFamilySite,
  }: RaNavbarProps) => {
    const isRoot =
      !destination || SETTINGS.DISABLE_CART_DOMAINS.includes(destination)
    const [dropdownIsOpen, setDropdownOpen] = useState(false)
    const [showNavbar, setShowNavbar] = useState(false)

    const { blogname, blogdescription, site_label, menus } = themeSettings

    const renderNavbar = useMemo(() => {
      return dropdownIsOpen || forceNavbar
    }, [forceNavbar, dropdownIsOpen])

    return (
      <div className={styles['ra-navbar']}>
        <RaNavbarInteraction
          isOpen={renderNavbar}
          setShowNavbar={setShowNavbar}>
          <div className={styles['ra-navbar__desktop']}>
            <RaContainer>
              <nav className={styles['ra-navbar__wrapper']}>
                <RaNavbarLogo
                  title={blogname}
                  subtitle={blogdescription}
                  logoIcon={site_label}
                  destination={destination}
                  isOpen={renderNavbar || showNavbar}
                  isFamilySite={isFamilySite}
                />
                <div className={styles['ra-navbar__menus']}>
                  {menus?.primary?.map((menu, i: number) => (
                    <nav
                      key={i}
                      className={styles['ra-navbar__menu-item']}
                      onMouseOver={() => setDropdownOpen(true)}
                      onMouseLeave={() => setDropdownOpen(false)}
                      onFocus={() => setDropdownOpen(true)}
                      onBlur={() => setDropdownOpen(false)}
                      tabIndex={0}
                      title={menu.link.title}>
                      {menu.link.url !== '#' ? (
                        <Link
                          href={stripFrontendUrl(menu.link.url)}
                          title={menu.link.title}
                          target={menu.link.target}
                          className={styles['ra-navbar__menu-title']}
                          tabIndex={-1}>
                          <RaTypography
                            component="span"
                            fontFamily="heading"
                            size="l"
                            color={
                              renderNavbar || showNavbar ? 'off-black' : 'light'
                            }>
                            {menu.link.title}
                            <RaIcon icon={ICONS.CHEVRON_DOWN} />
                          </RaTypography>
                        </Link>
                      ) : (
                        <div className={styles['ra-navbar__menu-title']}>
                          <RaTypography
                            component="span"
                            fontFamily="heading"
                            size="l"
                            color={
                              renderNavbar || showNavbar ? 'off-black' : 'light'
                            }>
                            {menu.link.title}
                            <RaIcon icon={ICONS.CHEVRON_DOWN} />
                          </RaTypography>
                        </div>
                      )}
                      <RaNavbarDropdown
                        key={i}
                        menu={menu}
                        removeTopPadding
                        isOpen={dropdownIsOpen}
                      />
                    </nav>
                  ))}
                </div>
                {!isRoot ? (
                  <RaButtonTravelplan hasNavbar={renderNavbar || showNavbar} />
                ) : (
                  <div style={{ width: 160 }}></div>
                )}
              </nav>
            </RaContainer>
          </div>
          <RaNavbarMobile
            {...themeSettings}
            isRoot={isRoot}
            destination={destination}
            showNavbar={renderNavbar || showNavbar}
            isFamilySite={isFamilySite}
          />
        </RaNavbarInteraction>
      </div>
    )
  }
)

RaNavbar.displayName = 'RaNavbar'
