'use client'
import { useMemo } from 'react'
import { RaContainer, RaIcon } from '@components/core/index.core'
import { capitalize, Loop } from '@utilities/helpers/core.helpers'
import { ICONS } from '@constants/icons.constants'
import Link from 'next/link'

import styles from './ra-breadrcrumbs.module.scss'

import { useSettings } from '@contexts/settings.context'

export interface RaBreadCrumbsProps {
  parentCategory?: string
}

export const RaBreadcrumbs = ({ parentCategory }: RaBreadCrumbsProps) => {

  const { params, themeSettings, destination, yoast_breadrcrumbs } =
    useSettings()

  const breadcrumbsList = useMemo(() => {
    // Not a product detail page
    if (!parentCategory) {
      if (!params) return null
      const crumbs = []

      params.forEach((param: string, i: number) => {
        const itemPath = crumbs?.map(({ raw }) => raw).join('/') + `/${param}`
        crumbs.push({
          index: i,
          raw: param,
          label: capitalize(param.replace(/-/gi, ' ')),
          path: crumbs.length > 0 ? `/${itemPath}` : `/${param}`,
        })
      })

      return crumbs
    } else {
      // Product detail page
      const regionParents = themeSettings?.region_parents

      const crumbs = []
      if (!yoast_breadrcrumbs) return null
      yoast_breadrcrumbs.forEach((item, i) => {
        crumbs.push({
          index: i,
          raw: item?.link?.url,
          label:
            i === 0
              ? capitalize(destination.replace(/-/gi, ' '))
              : item?.link?.title,
          path: item?.link?.url,
        })
      })

      // if (regionParents) {
      //   const parentSlug = regionParents.find(
      //     item => item[item.type.replaceAll('-', '_')] === parentCategory
      //   )
  
      //   const parent = {
      //     raw: `${yoast_breadrcrumbs[0].link.url}${parentSlug.parent_page?.post_name}`,
      //     path: `${yoast_breadrcrumbs[0].link.url}${parentSlug.parent_page?.post_name}`,
      //     label: parentSlug.parent_page?.post_title,
      //   }

      //   crumbs.splice(crumbs.length - 1, 0, parent)
      // }

      return crumbs
    }
  }, [params])

  const renderBreadCrumbs = useMemo(() => {
    return Loop(breadcrumbsList, (param, i: number) => {
      if (i === breadcrumbsList.length - 1)
        return <li key={param.index}>{param.label}</li>
      return (
        <li key={param.index}>
          <Link href={param.path}>{param.label}</Link>
          <RaIcon
            icon={ICONS.CHEVRON_RIGHT}
            color="dark"
          />
        </li>
      )
    })
  }, [])

  return (
    <RaContainer>
      <ul className={styles['ra-breadcrumbs']}>
        <li>
          <Link href="/">Home</Link>
          <RaIcon
            icon={ICONS.CHEVRON_RIGHT}
            color="dark"
          />
        </li>
        {renderBreadCrumbs}
      </ul>
    </RaContainer>
  )
}
